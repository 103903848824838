import Vuex from 'vuex'
import Vue from 'vue';
import auth from '@/modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isAuthorized: false,
        roles: [],
    },
    actions: {
        getRoles({dispatch, commit}) {
            return auth.getRoles()
                .then(response => {
                    commit("setRoles", response.data);
                })
                .catch(err => {
                    commit("setRoles", []);
                    if (err.response?.status === 401) {
                        commit("setAuthorized", false);
                        dispatch("sendLogoutMessage");
                    }
                });
        },
        refreshToken({dispatch, commit}) {
            return auth.refreshToken()
                .then(async () => {
                    commit("setAuthorized", true);
                    await dispatch("getRoles");
                })
                .catch(err => {
                    commit("setAuthorized", false);
                    dispatch("sendLogoutMessage");
                    throw err;
                });
        },
        login({dispatch, commit}, payload) {
            return auth.login(payload.login, payload.password)
                .then(async response => {
                    if (response.data.changePassword !== true) {
                        commit("setAuthorized", true);
                        await dispatch("getRoles");
                    }
                    return Promise.resolve(response);
                })
                .catch(err => {
                    commit("setAuthorized", false);
                    throw err;
                });
        },
        logout({commit, dispatch}) {
            return auth.logout()
                .then(() => {
                    commit("setAuthorized", false);
                    commit("setRoles", []);
                    dispatch("sendLogoutMessage");
                })
                .catch(err => {
                    if (err.response?.status === 401) {
                        commit("setAuthorized", false);
                        commit("setRoles", []);
                        dispatch("sendLogoutMessage");
                    }
                    throw err;
                });
        },
        changePassword({}, payload) {
            return auth.changePassword(payload.login, payload.password, payload.newPassword);
        },
        sendLogoutMessage({getters}) {
            if (!getters.isAuthorized && window.parent !== window.self) {
                window.parent.postMessage("LOGOUT", process.env.VUE_APP_ADMIN_UI_URL);
            }
        }
    },
    mutations: {
        setRoles(state, roles) {
            state.roles = roles;
        },
        setAuthorized(state, isAuthorized) {
            state.isAuthorized = isAuthorized;
        }
    },
    getters: {
        isAdmin(state) {
            return state.roles.includes('ADMIN');
        },
        isAuthorized(state) {
            return state.isAuthorized;
        },
        isRolesEmpty(state) {
            return state.roles.length === 0;
        }
    }
});
