import Vue from 'vue';
import Router from 'vue-router';
import Login from './components/Login';
import FeedList from './components/feed/FeedList';
import FeedItemEdit from './components/feed/FeedItemEdit';
import store from "@/store";
Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {path: '/login', component: Login,  meta: {title: 'Вход'}},
	    {path: '/', component: FeedList, meta: { title: 'Список уведомлений'}},
        {path: '/create', component: FeedItemEdit, meta: { title: 'Создание уведомления'}, props: {creating: true}},
        {path: '/:id', component: FeedItemEdit, meta: { title: 'Редактирование уведомления'}}
    ]
});

router.beforeEach(async (to, from, next) => {
    if (from.path !== '/login' && to.path !== '/login' && store.getters.isRolesEmpty) {
        await store.dispatch("refreshToken")
            .catch(() => {});
    }

    if (!store.getters.isAuthorized && '/login' !== to.path) {
        return next('/login');
    }
    if (store.getters.isAuthorized && '/login' === to.path) {
        return next('/');
    }
    if (store.getters.isAuthorized && store.getters.isRolesEmpty && '/' !== to.path) {
        return next('/');
    }
    if (!store.getters.isAdmin && '/create' === to.path) {
        return next('/');
    }
    return next();
});

export default router;
